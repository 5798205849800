import React from 'react'
import { BgImage } from 'gbimage-bridge'
import { getImage } from 'gatsby-plugin-image'
import SocialIcons from '../elements/SocialIcons'
import WdcButton from '../elements/WdcButton'

const Hero = ({ page, socialIcons }) => {
  const { callToAction, callToActionLink, hero, title, subtitle } = page
  const link = callToActionLink?.pageLink ? callToActionLink.pageLink : ''
  const image = getImage(hero)
  const imageStack = [`linear-gradient(rgba(0, 0, 0, 0.25),rgba(0, 0, 0, 0.25))`, image]

  return (
    <section className='relative mt-navbar md:mt-navbarDesktop bg-secondary h-40vh md:h-80vh'>
      <BgImage image={imageStack} alt={title} className='h-full w-full' as='div'>
        <div className='container h-full flex items-center'>
          <div className='z-10 relative px-4 xl:px-8'>
            <h1>{title}</h1>
            {subtitle && (
              <span className='block mt-4'>
                <span
                  className='font-spar text-gray-50 tracking-wide text-lg md:text-2xl pt-1.5 pb-1 bg-black ml-3.5'
                  style={{ boxShadow: '10px 0 0 black, -10px 0 0 black' }}
                >
                  {subtitle}
                </span>
              </span>
            )}
            {(link || callToAction) && (
              <div className='flex justify-start ml-1 md:ml-1.5 mt-8'>
                <WdcButton buttonText={callToAction} buttonLink={link !== '' ? link : '#main'} />
              </div>
            )}
          </div>
        </div>
      </BgImage>
      {socialIcons && (
        <div className='flex justify-end'>
          <SocialIcons />
        </div>
      )}
    </section>
  )
}

Hero.defaultProps = {
  socialIcons: false,
}

export default Hero
