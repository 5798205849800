import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Loadable from 'react-loadable'
import SocialIcons from '../elements/SocialIcons'
import WdcButton from '../elements/WdcButton'

const loader = () => <span>WE DO CODE.</span>

const HomeHero = ({ page, socialIcons }) => {
  const { callToAction, callToActionLink, hero, title, subtitle } = page
  const link = callToActionLink?.pageLink ? callToActionLink.pageLink : ''
  const image = getImage(hero)

  const LoadableComponent = Loadable({
    loader: () => import('../elements/Typewriter.js'),
    loading: loader,
  })

  return (
    <>
      <section className='relative mt-navbar md:mt-navbarDesktop bg-secondary h-40vh md:h-75vh'>
        <div className='container h-full flex items-center'>
          <div className='z-10 relative px-4 xl:px-8'>
            <h1>{title}</h1>
            {subtitle && (
              <span className='block mt-4'>
                <span
                  className='md:hidden font-spar text-gray-50 tracking-wide text-lg md:text-2xl pt-1.5 pb-1 bg-black ml-3.5'
                  style={{ boxShadow: '10px 0 0 black, -10px 0 0 black' }}
                >
                  {subtitle}
                </span>
                <span className='hidden md:inline-flex bg-black px-2 text-2xl inline-flex ml-1'>
                  <LoadableComponent />
                </span>
              </span>
            )}
            {link && (
              <div className='flex justify-start ml-1 md:ml-1.5 mt-2 md:mt-4 lg:mt-8'>
                <WdcButton buttonText={callToAction} buttonLink={link} />
              </div>
            )}
          </div>
        </div>
        <div className='absolute inset-0 h-auto'>
          <GatsbyImage image={image} alt={title} className='h-full w-full filter brightness-75' as='section' />
        </div>
        {socialIcons && (
          <div className='flex justify-end'>
            <SocialIcons />
          </div>
        )}
      </section>
    </>
  )
}

HomeHero.defaultProps = {
  socialIcons: false,
}

export default HomeHero
