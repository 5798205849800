import React from 'react'
import { FaFacebookSquare, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { useSiteMetadata } from '../../custom-query-hooks/useSiteMetadata'

const SocialIcons = () => {
  const {
    fbUrl: metaFbUrl,
    twitterUrl: metaTwitterUrl,
    linkedInUrl: metaLinkedInUrl,
  } = useSiteMetadata()

  return (
    <div className='flex flex-row items-center pt-1 md:pt-2'>
      <a
        href={metaLinkedInUrl}
        title='We Do Code on LinkedIn'
        target='_blank'
        rel='noreferrer'
      >
        <FaLinkedin className='social-icon linkedIn' alt='LinkedIn Icon' />
      </a>
      <a
        href={metaTwitterUrl}
        title='We Do Code on Twitter'
        target='_blank'
        rel='noreferrer'
      >
        <FaTwitter className='social-icon twitter' alt='Twitter Icon' />
      </a>
      <a
        href={metaFbUrl}
        title='We Do Code on Facebook'
        target='_blank'
        rel='noreferrer'
      >
        <FaFacebookSquare
          className='social-icon facebook'
          alt='Facebook Icon'
        />
      </a>
    </div>
  )
}

export default SocialIcons
