import React, { useState, useEffect } from 'react'
import CookieConsent from 'react-cookie-consent'
import Footer from './sections/Footer'
import Hero from './sections/Hero'
import HomeHero from './sections/HomeHero'
import { Link } from 'gatsby'
import Navbar from './sections/Navbar'
import ScrollToTop from './elements/ScrollToTop'
import Seo from './Seo'
import WdcButton from './elements/WdcButton'

const Layout = ({ children, page, socialIcons, showShadow, showProgress, showBelow }) => {
  const { title, seoTitle, seoDescription, seoKeywords } = page
  const [showGetInTouch, setShowGetInTouch] = useState(false)

  const handleScroll = () => {
    if (window.pageYOffset > 620) {
      if (!showGetInTouch) setShowGetInTouch(true)
    } else {
      if (showGetInTouch) setShowGetInTouch(false)
    }
  }

  useEffect(() => {
    window.addEventListener(`scroll`, handleScroll)
    return () => window.removeEventListener(`scroll`, handleScroll)
  })

  return (
    <>
      <Seo title={seoTitle ? seoTitle : title} description={seoDescription} keywords={seoKeywords} />
      <div id='skip'>
        <a href='#main' title='skip to main content'>
          skip to main content
        </a>
      </div>
      <header>
        <Navbar showShadow={showShadow} showProgress={showProgress} />
        {page.name === 'Home' ? <HomeHero page={page} socialIcons={socialIcons} /> : <Hero page={page} socialIcons={socialIcons} />}
      </header>
      <main id='main'>
        {children}
        <div className='hidden lg:block fixed top-1/2 z-50 -right-16 md:-right-20 md:-mr-1'>
          {showGetInTouch && (
            <WdcButton
              buttonText='Get in Touch now'
              buttonLink='/contact-us'
              className='transform rotate-90 hover:scale-150 -translate-y-1/2'
              showIcon={true}
            />
          )}
        </div>
        <ScrollToTop showBelow={showBelow} />
      </main>
      <Footer />
      <CookieConsent
        location='bottom'
        buttonText='I Understand'
        cookieName='gatsby-gdpr-google-analytics'
        style={{ background: '#4B4955' }}
        buttonStyle={{
          background: '#f8ad43',
          borderRadius: '6px',
          color: '#4B4955',
          paddingx: '2px',
        }}
      >
        This website uses cookies to enhance the user experience on this website. No personal data is stored and we{' '}
        <span className='underline'>do not</span> track your activity outside this website. For more information view our{' '}
        <Link to='/privacy-policy' className='underline'>
          Privacy Policy.
        </Link>
      </CookieConsent>
    </>
  )
}

Layout.defaultProps = {
  children: '',
  page: { title: '404 - PAGE NOT FOUND' },
  socialIcons: false,
  showShadow: true,
  showProgress: true,
}

export default Layout
