import React, { useEffect, useState } from 'react'
import { IoCaretUpCircleSharp } from 'react-icons/io5'

const ScrollToTop = ({ showBelow }) => {
  const [show, setShow] = useState(showBelow ? true : false)

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  })

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }

  return (
    <div>
      {show && (
        <button
          className='z-10 fixed transition-all duration-300'
          style={{ bottom: '10vh', right: '2%' }}
          onClick={handleClick}
        >
          <IoCaretUpCircleSharp
            title='back to top'
            size={64}
            className='text-primary'
          />
        </button>
      )}
    </div>
  )
}

export default ScrollToTop
